import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Spinner,
} from "reactstrap";
import ValidatableForm from "./validatable-form";

const OffensiveContentReportModal = (props) => {
  const {
    showModal,
    setShowModal,
    currentUser,
    reportedContent,
    reportedContentType,
    authenticityToken,
    reportContentPath,
  } = props;

  const [submitting, setSubmitting] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleError = () => {
    setShowErrorMessage(true);
    setSubmitting(false);
  };

  const closeDialog = () => {
    if (submitting) return;
    setShowModal(false);
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={closeDialog}
      fade={false}
      centered
      scrollable
    >
      <ModalHeader toggle={closeDialog}>
        Report Offensive Content
      </ModalHeader>          
      {showSuccessMessage ? (
        <ModalBody className="d-flex flex-column">
          <div>Report submitted succssfully.</div>
        </ModalBody>
        ) : (
          <>
          <ModalBody className="d-flex flex-column">
            <ValidatableForm
              action={reportContentPath}
              authenticityToken={authenticityToken}
              id="report-content-form"
              onSuccess={setShowSuccessMessage(true)}
              onError={handleError}
              setSubmitting={setSubmitting}
            >
            {showErrorMessage &&
              <div className="alert alert-warning">Could not report offensive content.</div>
            }
            <p>Reporting {reportedContentType}: {reportedContent?.name || `Submission #${reportedContent?.id}`}</p>
            <p>Reported by: {currentUser.fullName} ({currentUser.email})</p>

            {authenticityToken && (
              <input
                type="hidden"
                name="authenticity_token"
                value={authenticityToken}
              />
            )}
            <input
              type="hidden"
              name="record_id"
              value={reportedContent?.id}
            />
            <input
              type="hidden"
              name="record_type"
              value={reportedContentType}
            />
            <input
              type="hidden"
              name="reporting_user_id"
              value={currentUser.id}
            />

            <div className="form-group">
              <label htmlFor="Comment">Comment</label>
              <textarea id="comment" className="form-control" required />
              <div className="invalid-feedback">
                Please provide details about the offensive content you are reporting.
              </div>
            </div>
          </ValidatableForm>
        </ModalBody>
        <ModalFooter>
          <div className="form-group">
            <button
              type="submit"
              form="report-content-form"
              className="btn btn-primary"
              disabled={submitting}
            >
              {submitting ? (
                <Spinner className="button-spinner" />
              ) : (
                <span>
                  <i className="fas fa-paper-plane" />
                </span>
              )}
              Submit Report
            </button>
          </div>
        </ModalFooter>
        </>
        )
      }
    </Modal>
  );
};

OffensiveContentReportModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  reportedContent: PropTypes.object,
  reportedContentType: PropTypes.string,
  currentUser: PropTypes.object.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  reportContentPath: PropTypes.string.isRequired,
};

OffensiveContentReportModal.defaultProps = {
  reportedContent: null,
  reportedContentType: "",
};

export default OffensiveContentReportModal;
