import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import {
  GROUP_PROP_TYPES,
  GROUP_SCORE_PROP_TYPES,
  USER_PROP_TYPES
} from "./constants/prop-types";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import ModalContent from "./modal-content";
import JoinedGroups from "./joined-groups-list";
import JoinGroupToChallengeModal from "./join-group-to-challenge-modal";
import axios from "axios";
import InviteGroupModal from "./invite-group-modal";
import VerificationCodeForm from "./verification-code-form";

const ChallengeGroups = (props) => {
  const {
    canJoinGroupsToChallenge,
    canInviteToChallenge,
    challengeState,
    challengeGroups,
    newChallengeGroupCreationsPath,
    challengeGroupsPath,
    challengeGroupPath,
    userGroups,
    authenticityToken,
    challengeInvitationsPath,
    challengeGroupUserAffinityPath,
    challengeGroupUserAffinitiesPath,
    refreshChallenge,
    canSubmitToChallenge,
    defaultMedia,
    setShowOffensiveContentReportDialog,
    setReportedContent,
    setReportedContentType,
    newVerificationCodePath,
    updateVerificationCodePath,
    currentUser
  } = props;

  const [showAddGroupDialog, setShowAddGroupDialog] = useState(false);
  const [showNewGroupDialog, setShowNewGroupDialog] = useState(false);
  const [showInviteGroupDialog, setShowInviteGroupDialog] = useState(false);
  const [
    showNewGroupSuccessfullyCreatedDialog,
    setShowNewGroupSuccessfullyCreatedDialog,
  ] = useState(false);
  const [
    showNewGroupSuccessfullyAddedDialog,
    setShowNewGroupSuccessfullyAddedDialog,
  ] = useState(false);
  const [lastTouchedGroup, setLastTouchedGroup] = useState(null);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [showVerificationCodeForm, setShowVerificationCodeForm] = useState(false);

  const challengeGroupUserAffinityId = useMemo(
    () =>
      challengeGroups.find((challengeGroup) => challengeGroup.userHasAffinity)
        ?.challengeGroupUserAffinityId || null,
    [challengeGroups]
  );

  const createChallengeGroupUserAffinity = async (groupId) => {
    const formData = new FormData();
    formData.append("group_id", groupId);
    formData.append("authenticity_token", authenticityToken);

    const response = await axios.post(
      challengeGroupUserAffinitiesPath,
      formData,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          accept: "application/json",
        },
      }
    );

    if (response.status === 204) {
      await refreshChallenge();
    } else {
      // TODO: handle error conditions
    }
  };

  const updateChallengeGroupUserAffinity = async (groupId) => {
    const formData = new FormData();
    formData.append("group_id", groupId);
    formData.append("authenticity_token", authenticityToken);

    const response = await axios.patch(
      challengeGroupUserAffinityPath.replace(
        /:id/,
        challengeGroupUserAffinityId
      ),
      formData,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          accept: "application/json",
        },
      }
    );

    if (response.status === 204) {
      await refreshChallenge();
    } else {
      // TODO: handle error conditions
    }
  };

  const addChallengeGroup = async (challengeGroup) => {
    setLastTouchedGroup(challengeGroup);
    await refreshChallenge();
  };

  const removeGroupFromChallenge = async (group) => {
    const response = await axios.delete(
      challengeGroupPath.replace(/:id/, group.id),
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": authenticityToken,
          accept: "application/json",
        },
      }
    );

    if (response.status === 204) {
      await refreshChallenge();
    } else {
      // TODO: actually handle an error
    }
  };

  useEffect(() => {
    setAvailableGroups(
      userGroups.filter(
        ({ id: groupId }) =>
          !challengeGroups.map(({ group: { id } }) => id).includes(groupId)
      )
    );
  }, [challengeGroups]);

  return (
    <>
      {canJoinGroupsToChallenge && (
        <div className="row">
          <div className="col">
            <button
              type="button"
              className="join-group-to-challenge btn btn-secondary mt-2 mb-2"
              onClick={() => setShowAddGroupDialog(true)}
            >
              <i className="fas fa-plus" />
              Join a Flock to This Challenge
            </button>
          </div>
        </div>
      )}

      <JoinGroupToChallengeModal
        showAddGroupDialog={showAddGroupDialog}
        setShowAddGroupDialog={setShowAddGroupDialog}
        setShowVerificationCodeForm={setShowVerificationCodeForm}
        setShowInviteGroupDialog={setShowInviteGroupDialog}
        challengeGroupsPath={challengeGroupsPath}
        groups={availableGroups}
        authenticityToken={authenticityToken}
        canInviteToChallenge={canInviteToChallenge}
        onAddChallengeGroup={async (group) => {
          await addChallengeGroup(group);
          setShowAddGroupDialog(false);
          setShowNewGroupSuccessfullyAddedDialog(true);
        }}
        defaultMedia={defaultMedia}
      />

      <InviteGroupModal
        showModal={showInviteGroupDialog}
        setShowModal={setShowInviteGroupDialog}
        authenticityToken={authenticityToken}
        challengeInvitationsPath={challengeInvitationsPath}
      />

      <VerificationCodeForm
        authenticityToken={authenticityToken}
        currentUser={currentUser}
        newVerificationCodePath={newVerificationCodePath}
        updateVerificationCodePath={updateVerificationCodePath}
        setShowModal={setShowVerificationCodeForm}
        showModal={showVerificationCodeForm}
        handleSuccess={() => {
          setShowVerificationCodeForm(false);
          setShowNewGroupDialog(true);
        }}
        />

      <ModalContent
        url={newChallengeGroupCreationsPath}
        isOpen={showNewGroupDialog}
        toggle={() => setShowNewGroupDialog(false)}
        onJsonResponse={async (group) => {
          await addChallengeGroup(group);
          setShowNewGroupDialog(false);
          setShowNewGroupSuccessfullyCreatedDialog(true);
        }}
      />

      <Modal
        isOpen={showNewGroupSuccessfullyCreatedDialog}
        toggle={() => setShowNewGroupSuccessfullyCreatedDialog(false)}
        fade={false}
        centered
      >
        <ModalHeader
          toggle={() => setShowNewGroupSuccessfullyCreatedDialog(false)}
        >
          Flock Created
        </ModalHeader>
        <ModalBody>
          Your new flock '{lastTouchedGroup?.name}' has been created and added
          to this challenge!
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setShowNewGroupSuccessfullyCreatedDialog(false)}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={showNewGroupSuccessfullyAddedDialog}
        toggle={() => setShowNewGroupSuccessfullyAddedDialog(false)}
        fade={false}
        centered
      >
        <ModalHeader
          toggle={() => setShowNewGroupSuccessfullyAddedDialog(false)}
        >
          Flock Added
        </ModalHeader>
        <ModalBody>
          {lastTouchedGroup?.name ? (
            <>
              Your flock '{lastTouchedGroup?.name}' has been added to this
              challenge!
            </>
          ) : (
            <>Your flock has been added to this challenge!</>
          )}
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setShowNewGroupSuccessfullyAddedDialog(false)}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>

      <JoinedGroups
        challengeState={challengeState}
        groupScores={challengeGroups}
        removeGroupFromChallenge={removeGroupFromChallenge}
        setChallengeGroupUserAffinity={
          challengeGroupUserAffinityId
            ? updateChallengeGroupUserAffinity
            : createChallengeGroupUserAffinity
        }
        canSubmitToChallenge={canSubmitToChallenge}
        defaultMedia={defaultMedia}
        setReportedContent={setReportedContent}
        setReportedContentType={setReportedContentType}
        setShowOffensiveContentReportDialog={setShowOffensiveContentReportDialog}
      />
    </>
  );
};

ChallengeGroups.propTypes = {
  canJoinGroupsToChallenge: PropTypes.bool.isRequired,
  canInviteToChallenge: PropTypes.bool.isRequired,
  challengeState: PropTypes.string.isRequired,
  challengeGroups: PropTypes.arrayOf(PropTypes.exact(GROUP_SCORE_PROP_TYPES))
    .isRequired,
  newChallengeGroupCreationsPath: PropTypes.string.isRequired,
  challengeGroupsPath: PropTypes.string.isRequired,
  challengeGroupPath: PropTypes.string.isRequired,
  userGroups: PropTypes.arrayOf(PropTypes.exact(GROUP_PROP_TYPES)).isRequired,
  authenticityToken: PropTypes.string.isRequired,
  challengeInvitationsPath: PropTypes.string.isRequired,
  challengeGroupUserAffinityPath: PropTypes.string.isRequired,
  challengeGroupUserAffinitiesPath: PropTypes.string.isRequired,
  refreshChallenge: PropTypes.func.isRequired,
  canSubmitToChallenge: PropTypes.bool.isRequired,
  defaultMedia: PropTypes.object.isRequired,
  setReportedContent: PropTypes.func.isRequired,
  setReportedContentType: PropTypes.func.isRequired,
  setShowOffensiveContentReportDialog: PropTypes.func.isRequired,
  newVerificationCodePath: PropTypes.string.isRequired,
  updateVerificationCodePath: PropTypes.string.isRequired,
  currentUser: PropTypes.exact(USER_PROP_TYPES).isRequired
};
ChallengeGroups.defaultProps = {};

export default ChallengeGroups;
