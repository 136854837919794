import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import ChallengeGroups from "./challenge-groups";
import { GROUP_PROP_TYPES, USER_PROP_TYPES } from "./constants/prop-types";
import ChallengeSubmissions from "./challenge-submissions";
import OffensiveContentReportModal from "./offensive-content-report-modal";
import axios from "axios";

const ChallengeGroupsSubmissions = (props) => {
  const {
    newChallengeGroupCreationsPath,
    challengeGroupsPath,
    challengeGroupPath,
    userGroups,
    authenticityToken,
    challengeInvitationsPath,
    challengeGroupUserAffinityPath,
    challengeGroupUserAffinitiesPath,
    challengeSubmissionsPath,
    challengeSubmissionPath,
    challengeMetadataPath,
    environment,
    mediaUploadParams,
    mediaUploadSignature,
    defaultMedia,
    currentUser,
    reportContentPath,
    newVerificationCodePath,
    updateVerificationCodePath
  } = props;

  const [submissions, setSubmissions] = useState([]);
  const [challengeGroups, setChallengeGroups] = useState([]);
  const [metadata, setMetadata] = useState(null);
  const [showOffensiveContentReportDialog, setShowOffensiveContentReportDialog] = useState(false);
  const [reportedContent, setReportedContent] = useState(null);
  const [reportedContentType, setReportedContentType] = useState(null);

  const refreshChallengeGroups = async () => {
    const response = await axios.get(challengeGroupsPath, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        accept: "application/json",
      },
    });

    if (response.status === 200) {
      setChallengeGroups(response.data);
    } else {
      // TODO: handle error conditions
    }
  };

  const refreshChallengeSubmissions = async () => {
    const response = await axios.get(challengeSubmissionsPath, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        accept: "application/json",
      },
    });

    if (response.status === 200) {
      setSubmissions(response.data);
    } else {
      // TODO: handle error conditions
    }
  };

  const refreshChallengeMetadata = async () => {
    const response = await axios.get(challengeMetadataPath, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        accept: "application/json",
      },
    });

    if (response.status === 200) {
      setMetadata(response.data);
    } else {
      // TODO: handle error conditions
    }
  };

  const refreshChallenge = () => {
    refreshChallengeGroups();
    refreshChallengeSubmissions();
    refreshChallengeMetadata();
  };

  useMemo(() => {
    refreshChallenge();
  }, []);

  if (!metadata) return null;

  return (
    <>
      <h3>The Competing Flocks</h3>
      <ChallengeGroups
        canJoinGroupsToChallenge={metadata.canJoinGroupsToChallenge}
        challengeState={metadata.challengeState}
        canSubmitToChallenge={metadata.canSubmitToChallenge}
        canInviteToChallenge={metadata.canInviteToChallenge}
        challengeGroups={challengeGroups}
        newChallengeGroupCreationsPath={newChallengeGroupCreationsPath}
        challengeGroupsPath={challengeGroupsPath}
        challengeGroupPath={challengeGroupPath}
        userGroups={userGroups}
        authenticityToken={authenticityToken}
        challengeInvitationsPath={challengeInvitationsPath}
        challengeGroupUserAffinityPath={challengeGroupUserAffinityPath}
        challengeGroupUserAffinitiesPath={challengeGroupUserAffinitiesPath}
        refreshChallenge={refreshChallenge}
        defaultMedia={defaultMedia}
        setShowOffensiveContentReportDialog={setShowOffensiveContentReportDialog}
        setReportedContent={setReportedContent}
        setReportedContentType={setReportedContentType}
        newVerificationCodePath={newVerificationCodePath}
        updateVerificationCodePath={updateVerificationCodePath}
        currentUser={currentUser}
      />

      <h3 id="challenge-submissions">The Submissions</h3>
      <ChallengeSubmissions
        challengeState={metadata.challengeState}
        challengeStartDate={new Date(metadata.challengeStartDate)}
        challengeEndDate={new Date(metadata.challengeEndDate)}
        submissions={submissions}
        authenticityToken={authenticityToken}
        challengeSubmissionsPath={challengeSubmissionsPath}
        challengeSubmissionPath={challengeSubmissionPath}
        refreshChallenge={refreshChallenge}
        canSubmitToChallenge={metadata.canSubmitToChallenge}
        environment={environment}
        mediaUploadSignature={mediaUploadSignature}
        mediaUploadParams={mediaUploadParams}
        defaultMedia={defaultMedia}
        setShowOffensiveContentReportDialog={setShowOffensiveContentReportDialog}
        setReportedContent={setReportedContent}
        setReportedContentType={setReportedContentType}
      />

      <OffensiveContentReportModal 
        showModal={showOffensiveContentReportDialog}
        setShowModal={setShowOffensiveContentReportDialog}
        currentUser={currentUser}
        reportedContent={reportedContent}
        reportedContentType={reportedContentType}
        authenticityToken={authenticityToken}
        reportContentPath={reportContentPath}
      />
    </>
  );
};

ChallengeGroupsSubmissions.propTypes = {
  newChallengeGroupCreationsPath: PropTypes.string.isRequired,
  challengeGroupsPath: PropTypes.string.isRequired,
  challengeGroupPath: PropTypes.string.isRequired,
  userGroups: PropTypes.arrayOf(PropTypes.exact(GROUP_PROP_TYPES)).isRequired,
  authenticityToken: PropTypes.string.isRequired,
  challengeInvitationsPath: PropTypes.string.isRequired,
  challengeGroupUserAffinityPath: PropTypes.string.isRequired,
  challengeGroupUserAffinitiesPath: PropTypes.string.isRequired,
  challengeSubmissionsPath: PropTypes.string.isRequired,
  challengeSubmissionPath: PropTypes.string.isRequired,
  challengeMetadataPath: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  mediaUploadParams: PropTypes.object.isRequired,
  mediaUploadSignature: PropTypes.string.isRequired,
  defaultMedia: PropTypes.object.isRequired,
  reportContentPath: PropTypes.string.isRequired,
  currentUser: PropTypes.exact(USER_PROP_TYPES).isRequired,
  newVerificationCodePath: PropTypes.string.isRequired,
  updateVerificationCodePath: PropTypes.string.isRequired
};

ChallengeGroupsSubmissions.defaultProps = {};

export default ChallengeGroupsSubmissions;
