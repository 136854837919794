import React, { useState } from "react";
import PropTypes from "prop-types";
import { USER_PROP_TYPES } from "./constants/prop-types";
import VerificationCodeForm from "./verification-code-form";

const VerificationCodeButton = (props) => {
  const {
    authenticityToken,
    currentUser,
    newVerificationCodePath,
    updateVerificationCodePath,
    redirectPath,
    objectType,
    icon
  } = props;

  const [showModal, setShowModal] = useState(false);

  const handleSuccess = () => {
    setShowModal(false);
    window.location.href = redirectPath;
  }

  return (
    <>
      <button type="button" className="btn btn-secondary" onClick={() => setShowModal(true)}>
        <i className={`fas fa-${icon}`}></i> Create a New {objectType}
      </button>

      <VerificationCodeForm
        authenticityToken={authenticityToken}
        currentUser={currentUser}
        newVerificationCodePath={newVerificationCodePath}
        updateVerificationCodePath={updateVerificationCodePath}
        setShowModal={setShowModal}
        showModal={showModal}
        handleSuccess={handleSuccess}
        />
    </>
  );
};

VerificationCodeButton.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  currentUser: PropTypes.exact(USER_PROP_TYPES).isRequired,
  newVerificationCodePath: PropTypes.string.isRequired,
  updateVerificationCodePath: PropTypes.string.isRequired,
  redirectPath: PropTypes.string.isRequired,
  objectType: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};

VerificationCodeButton.defaultProps = {};

export default VerificationCodeButton;
