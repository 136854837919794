import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import axios from "axios";

const buildFormData = (form) =>
  Array.from(form.elements).reduce((data, formElement) => {
    if (formElement.files && !formElement.files.length) return data;

    data.append(
      formElement.name,
      formElement.type === "file" ? formElement.files[0] : formElement.value
    );
    return data;
  }, new FormData());

const ModalContent = (props) => {
  const { url, isOpen, toggle, onJsonResponse } = props;

  const [dialogContainerElement, setDialogContainerElement] = useState(null);
  const modalFooter = useRef(null);
  const [rawHtml, setRawHtml] = useState(null);
  const [header, setHeader] = useState(null);
  const [body, setBody] = useState(null);
  const [form, setForm] = useState(null);
  const [buttons, setButtons] = useState(null);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.target;
    form.classList.add("was-validated");

    if (form.checkValidity()) {
      const response = await axios.post(`${form.action}`, buildFormData(form), {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          accept: "application/json, text/html;q=9",
        },
      });

      response.headers["content-type"].match(/application\/json/)
        ? onJsonResponse(response.data)
        : setRawHtml(response.data);
    }
  };

  useEffect(() => {
    if (!isOpen) return;

    axios(url, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        accept: "text/html",
      },
    }).then((response) => {
      setRawHtml(response.data);
    });
  }, [isOpen]);

  useEffect(() => {
    if (!!rawHtml) {
      const doc = new DOMParser().parseFromString(rawHtml, "text/html");
      const header = doc.querySelector("h1, h2, h3, h4, h5, h6");
      const formButtons = Array.from(
        doc.querySelectorAll(
          "form button, form input[type=button], form input[type=submit]"
        )
      );
      formButtons.forEach((button) => {
        button.remove();
      });
      setButtons(formButtons);
      header?.remove();
      setHeader(header?.innerHTML);
      setBody(doc.body.innerHTML);
    };
  }, [rawHtml]);

  useEffect(() => {
    if (!!body) {
      setForm(
        dialogContainerElement
          ? dialogContainerElement.querySelector("form")
          : null
      );

      window.document.dispatchEvent(
        new Event("DOMContentLoaded", {
          bubbles: true,
          cancelable: true,
        })
      );
    };
  }, [dialogContainerElement, body]);

  useEffect(() => {
    if (form) {
      form.addEventListener("submit", handleFormSubmit);

      modalFooter.current &&
        Array.from(modalFooter.current.childNodes).forEach((child) =>
          child.remove()
        );

      buttons.forEach((button) => modalFooter.current.appendChild(button));
    }
  }, [form, buttons]);

  return (
    <Modal
      isOpen={isOpen && !!body}
      toggle={toggle}
      fade={false}
      centered
      scrollable
    >
      <ModalHeader toggle={toggle}>{header}</ModalHeader>
      <ModalBody>
        <div
          ref={(node) => setDialogContainerElement(node)}
          dangerouslySetInnerHTML={{ __html: body }}
        />
      </ModalBody>
      {buttons?.length ? (
        <ModalFooter>
          <div ref={modalFooter} />
        </ModalFooter>
      ) : null}
    </Modal>
  );
};

ModalContent.propTypes = {
  url: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onJsonResponse: PropTypes.func,
};
ModalContent.defaultProps = {
  onJsonResponse: null,
};

export default ModalContent;
