import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { USER_PROP_TYPES } from "./constants/prop-types";
import { Modal, ModalBody, ModalHeader, ModalFooter, Spinner } from "reactstrap";
import ValidatableForm from "./validatable-form";
import axios from "axios";

const VerificationCodeForm = (props) => {
  const {
    authenticityToken,
    currentUser,
    newVerificationCodePath,
    updateVerificationCodePath,
    showModal,
    setShowModal,
    handleSuccess
  } = props;

  const [verificationCode, setVerificationCode] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    if (showModal) {
      setVerificationCode("");
      setSubmitting(false);
      generateVerificationCode();
    }
  }, [showModal]);

  const generateVerificationCode = async () => {
    const response = await axios.get(newVerificationCodePath, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        accept: "application/json",
      },
      params: { user_id: currentUser.id },
    });

    if (response.status === 200) {
      console.log('code was created');
    } else {
      // TODO: handle error conditions
    }
  };

  const handleError = () => {
    setShowErrorMessage(true);
    setSubmitting(false);
  };

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(false)}
        fade={false}
        centered
      >
        <ModalHeader toggle={() => setShowModal(false)}>
          Enter Verification code
        </ModalHeader>
        <ModalBody className="d-flex flex-column">
          A verification code has been sent to your email address.  Please enter it below.
          <ValidatableForm
            action={`${updateVerificationCodePath}`}
            method="patch"
            authenticityToken={authenticityToken}
            id="verification-code-form"
            onSuccess={handleSuccess}
            onError={handleError}
            setSubmitting={setSubmitting}
          >
            {authenticityToken && (
              <input
                type="hidden"
                name="authenticity_token"
                value={authenticityToken}
              />
            )}
            <input
              type="hidden"
              name="user_id"
              value={currentUser.id}
            />
            <input
              type="text"
              name="code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            {showErrorMessage &&
              <div className="alert alert-warning">Code is not valid</div>
            }
          </ValidatableForm>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </button>
          <button 
            type="submit"
            form="verification-code-form"
            className="btn btn-primary"
            disabled={submitting}
          >
            {submitting ? (
                <Spinner className="button-spinner" />
              ) : ""}
            Submit
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

VerificationCodeForm.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  currentUser: PropTypes.exact(USER_PROP_TYPES).isRequired,
  newVerificationCodePath: PropTypes.string.isRequired,
  updateVerificationCodePath: PropTypes.string.isRequired,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  handleSuccess: PropTypes.func.isRequired
};

VerificationCodeForm.defaultProps = {
  showModal: false
};

export default VerificationCodeForm;
